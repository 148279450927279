/********Phone***********************/
@media (min-width: 300px) {
    .content {
        min-height: 100%;
        min-width: 100%;
        background-color: White;

        position: absolute;
        display: flex;
        flex-direction: column;

        text-align: center;
        font-size: medium;
    }

    .accueil_photo {
        min-height: 80vh;
        margin: 0;
        border: 0;
        padding: 0;

        justify-content: center;

        background-image: url("../../Pictures/Accueil/photo_accueil.jpg");
        background-repeat: no-repeat;
        background-position: top, left;
        background-size: cover;
        filter: blur(4px);
    }

    .accueil_photo_text {
        min-height: 60vh;
        max-width: 100%;
        margin-top: 40%;
        margin-right: 10%;
        margin-left: 10%;
        border: 0;

        position: absolute;
        horiz-align: center;
        color: white;
    }

    .accueil_titre_photo {
        max-width: 100%;
        text-align: left;
        font-size: 250%;
        font-family: Gabarito;
        font-weight: bold;
        position: relative;
    }

    .accueil_sous_titre_photo {
        padding-bottom: 15%;
        text-align: left;
        font-size: 150%;
        font-family: Gabarito;
        font-weight: normal;
    }

    .accueil_btn_projet {
        min-height: 3%;
        align-self: center;
    }

    .accueil_button {
        width: 90%;
        border: solid white;
        margin: 1%;
        padding: 3%;

        color: white;
        font-family: Gabarito;
        font-style: normal;
        font-size: 115%;
        background-color: transparent;
    }
    .accueil_button:hover{
        width: 100%;
        border: solid white;
        margin: 1%;
        padding: 3%;

        color: black;
        font-family: Gabarito;
        font-style: normal;
        font-size: 115%;
        background-color: white;
        transition: 0.5s;
    }


    .apropos {
        max-width: 100%;
        margin: 10%;
        border: 0;
        padding-top: 10%;

        display: block;
        justify-content: center;

        text-align: left;
    }

    .apropos_gauche {}

    .apropos_titre_text {
        font-size: 200%;
        font-family: Gabarito;
        font-weight: bold;
    }

    .apropos_sous_titre_text {
        font-size: 120%;
        font-family: Gabarito;
        font-style: normal;
    }

    .apropos_droite{}

    .apropos_haut{}

    .apropos_text {
        padding-top: 10%;
        font-size: 100%;
        font-family: OpenSans;
        font-style: normal;
    }

    .apropos_bas_droite {
        margin-right: 10%;
        margin-left: 10%;
        min-height: 20%;
        padding-top: 5%;
        padding-bottom: 20%;

        text-align: left;
        font-family: Gabarito;
        font-style: normal;
        font-size: 110%;
        color: #008CBF;
    }
    .b{
        font-family: Gabarito;
    }
}
/* *******Tablets**********************/
@media (min-width: 768px) {
    .content {
        min-height: 100%;
        max-width: 100%;
        background-color: White;

        position: absolute;
        display: flex;
        flex-direction: column;

        text-align: center;
        font-size: medium;
    }
    .accueil_photo {
        min-height: 90vh;
    }

    .accueil_photo_text {
        min-width: 75%;
        position: absolute;
    }

    .accueil_titre_photo {
        text-align: center;
        font-size: 375%;
    }

    .accueil_sous_titre_photo {
        padding-top: 2%;
        text-align: center;
        font-size: 275%;
    }

    .accueil_btn_projet {

    }

    .accueil_button {
        min-height: 6%;
        width: 80%;
        padding: 1%;
        font-size: 175%;
    }

    .apropos {
        padding-top: 0;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    .apropos_gauche {
        width: 80%;
        padding-top: 40%;
        text-align: left;
        height:90%;

    }

    .apropos_titre_text {

    }

    .apropos_sous_titre_text {
        font-size:120%;
        padding-top: 2%;
    }

    .apropos_droite {
        width: 100%;
        text-align: left;
    }

    .apropos_haut {
        padding-top: 5%;
    }


    .apropos_text {
        padding-top: 5%;
        font-size: 115%;
    }

    .apropos_bas_droite {
        margin-right: 10%;
        margin-left: 10%;
        margin-bottom: 10%;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 125%;
    }
    .b{
        display: flex;
    }
}

/* ************************* PC ******************************* */
@media (min-width: 1024px) {
    /*Content*/
    .content {
    }

    .accueil_photo {
        min-height: 100vh;
        margin: 0;
        border: 0;
        padding: 0;
    }

    .accueil_photo_text {
        max-width: 100%;
        margin-top: 25%;
    }

    .accueil_titre_photo {
        text-align: center;
        font-size: 500%;
    }

    .accueil_sous_titre_photo {
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
        font-size: 350%;
    }

    .accueil_btn_projet {
        padding-top: 3%;
        align-self: center;

    }

    .accueil_button {
        border: solid white;
        width: 30%;
        padding: 1%;
        font-size: 150%;
    }

    .accueil_button:hover{
        width: 33%;
        border: solid white;
        padding: 1%;

        color: black;
        font-family: Gabarito;
        font-style: normal;
        font-size: 150%;
        background-color: white;
        transition: 0.5s;
    }

    .apropos {
        padding-top: 0;
        margin-bottom: 5%;
    }

    .apropos_gauche {
        padding-top: 15%;
        width: 100%;
    }

    .apropos_titre_text {
        font-size: 250%;
    }

    .apropos_sous_titre_text {
        padding-top: 0;
        font-size: 150%;
    }


    .apropos_droite {
    }

    .apropos_haut {
    }

    .apropos_text {
        padding: 0;
        font-size: 115%;
    }
    .apropos_bas_container{
        display: flex;
    }
    .apropos_bas_gauche{
        min-width: 50%;
    }
    .apropos_bas_droite {
        margin:0;
        margin-right: 10%;
        padding-bottom: 10%;
        font-size: 200%;
    }
    .b{
    }
}











