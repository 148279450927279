/*************PHONE******************/
@media (min-width: 300px) {
    .footer {
        max-width: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        background-color: #008CBF;
        bottom: 0;
        color: white;

        font-family: Gabarito, serif;
        font-size: 125%;
    }
    .footer_body {
        max-width: 100%;
        margin-right: 5%;
        margin-left: 5%;
        border:0;
        padding: 0;

        display: block;
        justify-content: center;
    }


    .footer_venez{
        height:100%;
        max-width: 100%;
        margin-right: 15%;
        margin-left: 15%;
        border:0;
        padding-top: 3%;



        text-align: left;
    }

    .footer_titre {
        width: 100%;
        margin: 0;
        border:0;
        padding-top: 15%;
        padding-bottom: 10%;

        text-align: center;
        font-size: 110%;

    }
    .footer_adresse_container {
        margin: 0;
        border:0;
        padding:0;

        text-align: left;
    }

    .footer_adresse {
        width: 100%;
        margin: 0;
        border: 0;
        padding-bottom: 5%;

        text-align: left;
        font-family: OpenSans;
        font-weight: normal;
        font-size: 75%;
    }
    .footer_adresse_nom{
        padding-bottom: 5%;
        width: 100%;
        font-size: 80%;
        font-family: OpenSans;
        font-weight: bold;
        text-align: left;
    }
    .footer_code_postal{
        display: inline;
        padding-left:5% ;
        font-family: OpenSans;
        font-weight: normal;
        font-size: 90%;
    }

    .footer_tel {
        margin: 0;
        border:0;
        padding-top: 10%;
        font-family: OpenSans;
        font-weight: normal;
        font-size: 75%;
    }
    .footer_RBQ {
        margin: 0;
        border:0;
        padding-top: 5%;
        font-family: OpenSans;
        font-weight: bold;
        font-size: 75%;
    }
    .footer_social{
        max-width: 100%;
        margin: 0;
        border:0;
        padding-top: 10%;
        padding-right: 20%;
        padding-left: 20%;


    }
    .footer_social_titre{
        font-size: 110%;
        margin: 0;
        border:0;
        padding-top: 0;
        padding-bottom: 10%;
        text-align: center;
        font-family: Gabarito;
    }
    .footer_social_logos{
        height:30% ;
        display:flex;
        align-items: center;
    }
    .footer_logo_fb{
        display: flex;
        margin:0;
        border:0;
        padding-right: 6%;
        padding-left: 6%;
        background-image: url("../../Pictures/Logo/Facebook.ico");
        background-repeat: no-repeat;
        background-position: 75% 10%;
        width: 50%;
        height: 50px;
    }
    .footer_logo_fb:hover{
        margin:0;
        border:0;
        padding-right: 6%;
        padding-left: 6%;
        background-image: url("../../Pictures/Logo/FacebookBleuPale.ico");
        background-repeat: no-repeat;
        background-position: 75% 10%;
        width: 50%;
        height: 50px;
    }
    .footer_logo_insta{
        display: flex;
        margin:0;
        border:0;
        padding-right: 6%;
        padding-left: 6%;
        background-image: url("../../Pictures/Logo/Instagram.ico");
        background-repeat: no-repeat;
        background-position: 25% 10%;
        width: 50%;
        height: 50px;


    }
    .footer_logo_insta:hover{
        margin:0;
        border:0;
        padding-right: 6%;
        padding-left: 6%;
        background-image: url("../../Pictures/Logo/InstagramBleuPale.ico");
        background-repeat: no-repeat;
        background-position: 25% 10%;
        width: 50%;
        height: 50px;

    }

    .footer_fin {
        padding-top: 10%;
        padding-right: 5%;
        padding-left: 5%;
        padding-bottom: 10%;
        max-width: 100%;
        font-size: 80%;
        font-family: Gabarito;
    }
}

/**********Tablette********************/
@media (min-width: 768px) {
    .footer {
        max-width: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        background-color: #008CBF;

        bottom: 0;

        color: white;
    }


    .footer_body {
        width: 100%;
        margin: 0;
        border: 0;
        padding: 0;

        display: flex;
        justify-content: center;
    }


    .footer_venez {
        height: 100%;
        width: 60%;
        margin: 0;
        border: 0;
        padding-top: 3%;
        padding-left: 15%;


        text-align: left;
    }

    .footer_titre {
        margin: 0;
        border: 0;
        padding-bottom: 5%;

        text-align: left;
        font-size: 100%;
    }

    .footer_adresse {
        margin: 0;
        border: 0;
        padding: 0;

        text-align: left;
    }

    .footer_adresse_nom {
        padding-top: 10%;
    text-align: left;
    }

    .footer_tel {
        margin: 0;
        border: 0;
        padding-top: 10%;
    }

    .footer_social {
        width: 50%;
        margin: 0;
        border: 0;
        padding-top: 3%;
        padding-right: 10%;

        text-align: center;
    }

    .footer_social_titre {
        font-size: 100%;
        margin: 0;
        border: 0;
        padding-top: 10%;
        padding-bottom: 5%;
    }

    .footer_social_logos {
        margin: 0;
        border: 0;
        padding: 2%;

    }

    .footer_logo_fb {
        margin: 0;
        border: 0;
        padding-right: 6%;
        padding-left: 6%;
    }

    .footer_logo_insta {
        margin: 0;
        border: 0;
        padding-right: 6%;
        padding-left: 6%;

    }

    .footer_fin {
        margin: 0;
        border: 0;
        padding-top: 5%;
        padding-bottom: 5%;
        font-size: 160%;
    }
}

/**********PC*/
@media (min-width: 1024px) {
    .footer {
        max-width: 100%;
        margin: 0;
        border:0;
        padding: 0;
        background-color: #008CBF;

        bottom: 0;

        color: white;
    }


    .footer_body {
        width: 100%;
        margin: 0;
        border:0;
        padding: 0;

        display: flex;
        justify-content: center;
    }


    .footer_venez{
        height:100%;
        width: 60%;
        margin: 0;
        border:0;
        padding-top: 3%;
        padding-left: 20%;
        padding-right: 15%;


        text-align: left;
    }

    .footer_titre {
        margin: 0;
        border:0;
        padding-bottom: 5%;

        text-align: left;
        font-size: 120%;
    }

    .footer_adresse_container {
        margin: 0;
        border:0;
        padding:0;

        text-align: left;
    }
    .footer_adresse_nom{
        padding-top: 5%;
        width: 110%;
    }
    .footer_adresse{
        width:90%;

    }

    .footer_code_postal{
        display: inline;
        padding-left:5% ;
    }

    .footer_tel {
        margin: 0;
        border:0;
        padding-top: 7%;
    }

    .footer_social {
        width: 50%;
        margin: 0;
        border:0;
        padding-top: 3%;
        padding-right: 20%;

        text-align: center;
    }
    .footer_social_titre{
        font-size: 120%;
        margin: 0;
        border:0;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .footer_social_logos{
        height:25% ;
        display:flex;
        align-items: center;
    }
    .footer_logo_fb{
        margin:0;
        border:0;
        padding-right: 6%;
        padding-left: 6%;
    }
    .footer_logo_insta{
        margin:0;
        border:0;
        padding-right: 6%;
        padding-left: 6%;

    }

    .footer_fin {
        margin:0;
        border:0;
        padding-top: 2%;
        padding-bottom: 2%;
        font-size: 160%;
    }
}


