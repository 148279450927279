@font-face {
    font-family: 'Gabarito';
    src: local('Gabarito'), url(components/fonts/Gabarito/Gabarito-VariableFont_wght.ttf) format('opentype'),
        url(components/fonts/Gabarito/Gabarito-VariableFont_wght.ttf) format('woff2');
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open-Sans'), url(components/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf) format('opentype');
}
