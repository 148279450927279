/********************Phone****************/
@media (min-width: 300px) {
    .joindre {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        min-height: 100vh;

    }

    .joindre_haut {
        height:30%;
        max-width:100%;
        margin-right:15%;
        margin-left: 15%;
        border:0;
        position: relative;
    }
    .joindre_titre {
        padding-top: 15%;
        font-size: 150%;
        text-align: left;
        font-family: Gabarito;
    }

    .joindre_text {
        padding-top: 7%;
        font-size: 100%;
        text-align: left;
        max-width: 100%;
        font-family: OpenSans;
    }
    .joindre_milieu {
        height: 60%;
        max-width:100%;
        border:0;
        margin-right:15%;
        margin-left: 15%;
        position: relative;
        display:block;
        justify-content:center;
        align-items:normal;
    }
    .joindre_gauche {
        text-align: left;
        position: relative;
        justify-content: center;
    }


    .joindre_bas_titre1 {
        font-size: 150%;
        padding-top: 15%;
        text-align: center;
        color: #008CBF;
        font-family: Gabarito;
    }
    .joindre_bas_titre2 {
        font-size: 150%;
        padding-top: 25%;
        padding-bottom: 6%;
        text-align: center;
        color: #008CBF;
        font-family: Gabarito;
    }
    .joindre_adresse_nom{
        padding-top: 5%;
        font-size: 100%;
        font-family: OpenSans;
        font-weight: bold;
    }
    .joindre_adresse {
        text-align: left;
        font-weight: normal;
        padding-bottom: 10%;
        font-size: 100%;
        font-family: OpenSans;
        font-weight: normal;
    }

    .joindre_code_postal{
        display: inline;
        padding-left:5% ;
    }


    .joindre_tel {
        padding-top: 10%;
        font-family: OpenSans;
        font-weight: normal;
    }

    .joindre_rbq{
        padding-top: 10%;;
        font-family: OpenSans;
        font-weight: bold;
    }



    .btncontainer{
        position: relative;
        display: block;
        justify-content: center;
        align-items: center;
        min-width: 100%;
    }
    .a{
        min-width: 100%;
        text-align: left;
    }
    .joindre_contactBtn {
        color: white;
        background-color: #008CBF;
        border: 0;
        height: 7vh;
        min-width:100%;
        font-size: 115%;
        font-family: Gabarito;
        font-weight: normal;
    }
    .joindre_contactBtn:hover {
        color: #008CBF;
        background-color: white;
        border: medium solid #008CBF;
        height: 7vh;
        min-width:100%;
        font-size: 115%;
        font-family: Gabarito;
        font-weight: normal;
    }

    .joindre_droite {
        padding:5%;
        justify-content: center;
    }

    .joindre_logo {
        display: flex;
        justify-content: center;
    }

    .joindre_logoFB {
        background-image: url("../../Pictures/Logo/Facebook2.ico");
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        justify-content: left;
        position: relative;
    }
    .joindre_logoFB:hover {
        background-image: url("../../Pictures/Logo/FacebookBleu.ico");
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        justify-content: left;
        position: relative;
    }

    .joindre_logoInsta {
        padding-left: 5vh;
        background-position: right 1px;
        background-image: url("../../Pictures/Logo/Instagram2.ico");
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;

        justify-content: right;
        position: relative;
    }
    .joindre_logoInsta:hover {
        background-image: url("../../Pictures/Logo/InstagramBleu.ico");
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        padding-left: 5vh;
        justify-content: right;
        position: relative;
    }

    .logo_apcha{
        padding-top: 15%;
        padding-bottom: 5%;
        width: 100%;
        scale: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .joindre_foot{
        max-width:100%;
        min-width: 100%;
        margin-top:auto;
        border:0;
        padding-top: 5%;
        padding-bottom: 5%;
        background-color: #008CBF;

        position: relative;
        bottom:0;

        font-size: 100%;
        text-align:center;
        font-family:Gabarito;
        color:white;
    }

}

/****************Tablette****************************/
@media (min-width: 768px) {
    .joindre {
    }

    .joindre_haut {

    }

    .joindre_titre {
        font-size: 250%;
        padding-top: 10%;
        padding-left: 1%;
    }

    .joindre_text {
        font-size: 125%;
        padding-top: 10%;
        padding-bottom: 15%;
    }
    .joindre_milieu {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
        display:flex;
        justify-content:center;
        align-items:normal;
        padding-bottom: 5%;
    }
    .joindre_gauche {
        width: 100%;
    }

    .joindre_bas_titre1 {

        padding: 0;
        text-align: left;
        font-size: 150%;
    }
    .joindre_bas_titre2 {
        padding: 0;
        font-size: 150%;
    }
    .joindre_adresse {

        font-size: 110%;
    }
    .joindre_adresse_nom{
        font-size: 110%;
    }

    .joindre_code_postal{
        display: inline;
        padding-left:5% ;
    }

    .joindre_tel {
        font-size: 110%;
        padding-top: 3%;
    }
    .joindre_rbq{
        padding-top: 3%;
        font-size: 110%;
    }

    .btncontainer{
        padding-top: 5%;
        position: relative;
        display: flex;
        justify-content: left;
        align-items: center;

    }
    .a{

    }
    .joindre_contactBtn {
        border: 0;
        min-width: 80%;
        height: 5vh;
        width: 130%;
        font-size: 125%;
    }

    .joindre_contactBtn:hover {
        color: #008CBF;
        background-color: white;
        border: medium solid #008CBF;
        min-width: 80%;
        height: 5vh;
        width: 130%;
        font-size: 125%;
    }


    .joindre_droite {
        padding: 0;
        width: 100%;
    }

    .joindre_logo {
        height: 30%;
        display: flex;
        align-items: center;
    }

    .joindre_logoFB {
        display: flex;
        background-image: url("../../Pictures/Logo/Facebook2.ico");
        background-repeat: no-repeat;
        background-position: 50% 10%;
        width: 30%;
        height: 50px;
    }
    .joindre_logoFB:hover {
        display: flex;
        background-image: url("../../Pictures/Logo/FacebookBleu.ico");
        background-repeat: no-repeat;
        background-position: 50% 10%;
        width: 30%;
        height: 50px;
    }

    .joindre_logoInsta {
        padding-left: 0;
        display: flex;
        background-image: url("../../Pictures/Logo/Instagram2.ico");
        background-repeat: no-repeat;
        background-position: 50% 10%;
        width: 30%;
        height: 50px;
    }
    .joindre_logoInsta:hover {
        padding-left: 0;
        background-image: url("../../Pictures/Logo/InstagramBleu.ico");
        background-repeat: no-repeat;
        background-position: 50% 10%;
        width: 30%;
        height: 50px;
    }
    .logo_apcha{
        padding-top: 40%;
        padding-bottom: 5%;
        width: 100%;
        scale: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .joindre_foot {
        font-size: 200%;
    }

    .foot_email{
    }
}

/*************PC********************** */
@media (min-width: 1024px) {
    .joindre {
    }

    .joindre_haut {
    }

    .joindre_titre {
        font-size: 300%;
    }

    .joindre_text {
        padding-top: 5%;
        padding-bottom: 10%;
        width:70%;
        font-size: 130%;

    }
    .joindre_milieu {
        padding-top: 1%;
    }
    .joindre_gauche {
        width:100%;
    }
    .joindre_bas_titre1 {
        font-size: 250%;
        margin-left: 0;
    }

    .joindre_bas_titre2 {
        font-size: 250%;
        margin-left: 0;
    }
    .joindre_adresse {
        margin-left: 0;
    }
    .joindre_adresse_nom{
    }

    .joindre_code_postal{
       padding-left: 2%;
    }
    .joindre_tel {
    }

    .joindre_rbq{

    }

    .btncontainer{
    padding-left: 0;
    }
    .joindre_contactBtn {
        height: 6vh;
        width: 150% ;
    }

    .joindre_contactBtn:hover{
        height: 6vh;
        width: 150%;
    }

    .joindre_droite {
        width: 100%;
    }

    .joindre_logo {
        height: 10vh;
        display: flex;
        align-items: center;
    }

    .joindre_logoFB {
        display: flex;
        background-image: url("../../Pictures/Logo/Facebook2.ico");
        background-repeat: no-repeat;
        background-position: 50% 10%;
        width: 20%;
        height: 50px;
    }
    .joindre_logoFB:hover {
        display: flex;
        background-image: url("../../Pictures/Logo/FacebookBleu.ico");
        background-repeat: no-repeat;
        background-position: 50% 10%;
        width: 20%;
        height: 50px;
    }

    .joindre_logoInsta {
        padding-left: 0;
        display: flex;
        background-image: url("../../Pictures/Logo/Instagram2.ico");
        background-repeat: no-repeat;
        background-position: 50% 10%;
        width: 20%;
        height: 50px;
    }
    .joindre_logoInsta:hover {
        padding-left: 0;
        background-image: url("../../Pictures/Logo/InstagramBleu.ico");
        background-repeat: no-repeat;
        background-position: 50% 10%;
        width: 20%;
        height: 50px;
    }

    .logo_apcha{
        padding-top: 10%;
        scale:40%
    }

    .joindre_foot {
        padding-top: 5%;
        font-size: 300%;
    }
    .foot_email{
    }
}